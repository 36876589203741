'use client'

import { NextUIProvider } from '@nextui-org/react'
import React from 'react'

type Props = { children: React.ReactNode }

const NextUi = (props: Props) => {
  return <NextUIProvider>{props.children}</NextUIProvider>
}

export default NextUi
